/** @format */

//language
export const LANGUAGE = "LANGUAGE";
export const LANGUAGE_TYPE = "LANGUAGE_TYPE";

//Signup
export const SIGNUP_LOADING = "SIGNUP_LOADING";
export const SIGNUP_SUCCESSFUL = "SIGNUP_SUCCESSFUL";
export const SIGNUP_FAILED = "SIGNUP_FAILED";

//SignIn
export const SIGNIN_LOADING = "SIGNIN_LOADING";
export const SIGNIN_SUCCESSFUL = "SIGNIN_SUCCESSFUL";
export const SIGNIN_FAILED = "SIGNIN_FAILED";

//Apple Login
export const APPLE_LOGIN_LOADING = "APPLE_LOGIN_LOADING";
export const APPLE_LOGIN_SUCCESSFUL = "APPLE_LOGIN_SUCCESSFUL";
export const APPLE_LOGIN_FAILED = "APPLE_LOGIN_FAILED";

//Forgot Password
export const FORGOT_PASSWORD_lOADING = "FORGOT_PASSWORD_lOADING";
export const FORGOT_PASSWORD_SUCCESSFUL = "FORGOT_PASSWORD_SUCCESSFUL";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

// social login
export const GOOGLE_SIGNIN_SUCCESSFULL = "GOOGLE_SIGNIN_SUCCESSFULL";
export const GOOGLE_SIGNIN_FAILED = "GOOGLE_SIGNIN_FAILED";

//  social signup
export const SOCIAL_SIGNUP_LOADING = "SOCIAL_SIGNUP_LOADING";
export const SOCIAL_SIGNUP_FAILED = "SOCIAL_SIGNUP_FAILED";
export const SOCIAL_SIGNUP_SUCCESSFULL = "SOCIAL_SIGNUP_SUCCESSFULL";

//Verify OTP
export const VERIFY_OTP_LOADING = "VERIFY_OTP_LOADING";
export const VERIFY_OTP_SUCCESSFUL = "VERIFY_OTP_SUCCESSFUL";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";

//Resend OTP
export const RESEND_OTP_LOADING = "RESEND_OTP_LOADING";
export const RESEND_OTP_SUCCESSFUL = "RESEND_OTP_SUCCESSFUL";
export const RESEND_OTP_FAILED = "RESEND_OTP_FAILED";

//Reset Password
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESSFUL = "RESET_PASSWORD_SUCCESSFUL";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

// update user details
export const UPDATE_USER_DETAIL_LOADING = "UPDATE_USER_DETAIL_LOADING";
export const UPDATE_USER_DETAIL_SUCCESSFULL = "UPDATE_USER_DETAIL_SUCCESSFULL";
export const UPDATE_USER_DETAIL_FAILED = "UPDATE_USER_DETAIL_FAILED";

// updated user details reducer
export const UPDATED_PROFILE_DATA = "UPDATED_PROFILE_DATA";

// user details on login
export const USER_DETAILS = "USER_DETAILS";

// hire expert
export const HIRE_EXPERT_HELP_LOADING = "HIRE_EXPERT_HELP_LOADING";
export const HIRE_EXPERT_HELP_SUCCESSFULL = "HIRE_EXPERT_HELP_SUCCESSFULL";
export const HIRE_EXPERT_HELP_FAILED = "HIRE_EXPERT_HELP_FAILED";

// add user role
export const ADD_USER_ROLE_FAILED = "ADD_USER_ROLE_FAILED";
export const ADD_USER_ROLE_SUCCESSFULL = "ADD_USER_ROLE_SUCCESSFULL";

//  post startuo form details
export const ADD_STARTUP_LOADING = "ADD_STARTUP_LOADING";
export const ADD_STARTUP_DETAILS_FAILED = "ADD_STARTUP_DETAILS_FAILED";
export const ADD_STARTUP_DETAILS_SUCCESSFULL =
  "ADD_STARTUP_DETAILS_SUCCESSFULL";

// add marketing code
export const ADD_MARKETING_CODE_LOADING = "ADD_MARKETING_CODE_LOADING";
export const ADD_MARKETING_CODE_FAILED = "ADD_MARKETING_CODE_FAILED";
export const ADD_MARKETING_CODE_SUCCESSFULL = "ADD_MARKETING_CODE_SUCCESSFULL";

// add marketing code for without startup id
export const ADD_MARKETING_CODE_WITHOUT_ID_LOADING =
  "ADD_MARKETING_CODE_WITHOUT_ID_LOADING";
export const ADD_MARKETING_CODE_WITHOUT_ID_FAILED =
  "ADD_MARKETING_CODE_WITHOUT_ID_FAILED";
export const ADD_MARKETING_CODE_WITHOUT_ID_SUCCESSFULL =
  "ADD_MARKETING_CODE_WITHOUT_ID_SUCCESSFULL";

// add settings
export const ADD_SETTING_LOADING = "ADD_SETTING_LOADING";
export const ADD_SETTING_SUCCESSFULL = "ADD_SETTING_SUCCESSFULL";
export const ADD_SETTING_FAILED = "ADD_SETTING_FAILED";

// post business verification step
export const ADD_BUSINESS_VERIFICATION_SUCCESSFULL =
  "ADD_BUSINESS_VERIFICATION_SUCCESSFULL";
export const ADD_BUSINESS_VERIFICATION_FAILED =
  "ADD_BUSINESS_VERIFICATION_FAILED";
export const ADD_BUSINESS_VERIFICATION_LOADING =
  "ADD_BUSINESS_VERIFICATION_LOADING";

//  add startup id
export const ADD_STARTUP_ID = "ADD_STARTUP_ID";

// add favorite
export const ADD_FAVORITE_SUCCESSFULL = "ADD_FAVORITE_SUCCESSFULL";
export const ADD_FAVORITE_FAILED = "ADD_FAVORITE_FAILED";
export const ADD_FAVORITE_LOADING = "ADD_FAVORITE_LOADING";

// add promo code
export const ADD_PROMO_CODE_SUCCESSFULL = "ADD_PROMO_CODE_SUCCESSFULL";
export const ADD_PROMO_CODE_FAILED = "ADD_PROMO_CODE_FAILED";
export const ADD_PROMO_CODE_LOADING = "ADD_PROMO_CODE_LOADING";

// post buyer request
export const SEND_BUYER_REQUEST_SUCCESSFULL = "SEND_BUYER_REQUEST_SUCCESSFULL";
export const SEND_BUYER_REQUEST_FAILED = "SEND_BUYER_REQUEST_FAILED";
export const SEND_BUYER_REQUEST_LOADING = "SEND_BUYER_REQUEST_LOADING";

// report startup
export const REPORT_STARTUP_SUCCESSFULL = "REPORT_STARTUP_SUCCESSFULL";
export const REPORT_STARTUP_FAILED = "REPORT_STARTUP_FAILED";
export const REPORT_STARTUP_LOADING = "REPORT_STARTUP_LOADING";

// clear unread notification
export const CLEAR_UNREAD_NOTIFICATION_LOADING =
  "CLEAR_UNREAD_NOTIFICATION_LOADING";
export const CLEAR_UNREAD_NOTIFICATION_SUCCESSFULL =
  "CLEAR_UNREAD_NOTIFICATION_SUCCESSFULL";
export const CLEAR_UNREAD_NOTIFICATION_FAILED =
  "CLEAR_UNREAD_NOTIFICATION_FAILED";

// change password
export const CHANGE_PASSWORD_SUCCESSFUL = "CHANGE_PASSWORD_SUCCESSFUL";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";

// change language
export const CHANGE_LANGUAGE_SUCCESSFUL = "CHANGE_LANGUAGE_SUCCESSFUL";
export const CHANGE_LANGUAGE_FAILED = "CHANGE_LANGUAGE_FAILED";
export const CHANGE_LANGUAGE_LOADING = "CHANGE_LANGUAGE_LOADING";

// change email request
export const CHANGE_EMAIL_REQUEST_SUCCESSFUL =
  "CHANGE_EMAIL_REQUEST_SUCCESSFUL";
export const CHANGE_EMAIL_REQUEST_LOADING = "CHANGE_EMAIL_REQUEST_LOADING";
export const CHANGE_EMAIL_REQUEST_FAILED = "CHANGE_EMAIL_REQUEST_FAILED";

// change phone numnber request
export const CHANGE_PHONE_REQUEST_SUCCESSFUL =
  "CHANGE_PHONE_REQUEST_SUCCESSFUL";
export const CHANGE_PHONE_REQUEST_LOADING = "CHANGE_PHONE_REQUEST_LOADING";
export const CHANGE_PHONE_REQUEST_FAILED = "CHANGE_PHONE_REQUEST_FAILED";

// change phone numnber
export const CHANGE_PHONE_SUCCESSFUL = "CHANGE_PHONE_SUCCESSFUL";
export const CHANGE_PHONE_LOADING = "CHANGE_PHONE_LOADING";
export const CHANGE_PHONE_FAILED = "CHANGE_PHONE_FAILED";

// verify otp
export const VERIFY_OTP_CHANGE_EMAIL_SUCCESSFUL =
  "VERIFY_OTP_CHANGE_EMAIL_SUCCESSFUL";
export const VERIFY_OTP_CHANGE_EMAIL_FAILED = "VERIFY_OTP_CHANGE_EMAIL_FAILED";
export const VERIFY_OTP_CHANGE_EMAIL_LOADING =
  "VERIFY_OTP_CHANGE_EMAIL_LOADING";

// verify new otp change
export const VERIFY_NEW_OTP_CHANGE_EMAIL_SUCCESSFUL =
  "VERIFY_NEW_OTP_CHANGE_EMAIL_SUCCESSFUL";
export const VERIFY_NEW_OTP_CHANGE_EMAIL_FAILED =
  "VERIFY_NEW_OTP_CHANGE_EMAIL_FAILED";
export const VERIFY_NEW_OTP_CHANGE_EMAIL_LOADING =
  "VERIFY_NEW_OTP_CHANGE_EMAIL_LOADING";

// change email
export const CHANGE_EMAIL_SUCCESSFUL = "CHANGE_EMAIL_SUCCESSFUL";
export const CHANGE_EMAIL_LOADING = "CHANGE_EMAIL_LOADING";
export const CHANGE_EMAIL_FAILED = "CHANGE_EMAIL_FAILED";

// reset unread count
export const UPDATE_UNREAD_COUNT = "UPDATE_UNREAD_COUNT";
export const CLEAR_UNREAD_COUNT = "CLEAR_UNREAD_COUNT";

// resend verification code
export const RESEND_VERIFICATION_CODE_SUCCESSFUL =
  "RESEND_VERIFICATION_CODE_SUCCESSFUL";
export const RESEND_VERIFICATION_CODE_LOADING =
  "RESEND_VERIFICATION_CODE_LOADING";
export const RESEND_VERIFICATION_CODE_FAILED =
  "RESEND_VERIFICATION_CODE_FAILED";

// updated user
export const UPDATED_USER = "UPDATED_USER";

// cancel subscription
export const CANCEL_SUBSCRIPTION_SUCCESSFULL =
  "CANCEL_SUBSCRIPTION_SUCCESSFULL";
export const CANCEL_SUBSCRIPTION_FAILED = "CANCEL_SUBSCRIPTION_FAILED";
export const CANCEL_SUBSCRIPTION_LOADING = "CANCEL_SUBSCRIPTION_LOADING";
