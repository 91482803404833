/** @format */

export const EndPoint = {
  usersSignUp: "users/sign-up",
  usersSignIn: "users/login",
  usersSocialSignIn: "users/social-login",
  usersForgotPassword: "users/forget-password",
  deviceToken: "users/device-token",
  usersVerify: "users/verification",
  usersVerifyNewEmail: "users/change-email-verification",
  usersResetPassword: "users/reset-password",
  usersGetSingleUser: "users",
  usersDetails: "users/details",
  usersResendVerificationCode: "users/resend-verification-code",
  uploadFile: "/file-upload",
  updateUserDetail: "users/update-details",
  getHireExpertConnect: "hire-expert/connect-hire-expert",
  addHireExpertHelp: "hire-expert/help-desk",
  addUserRole: "users/add-role",
  getSingleHireExpert: "hire-expert/hire-expert",
  getStartupSellerForm: "form/seller-form",
  formPostStartupDetails: "form/start-up-details",
  updateFormStatusDetails: "form/update-Form-details",
  marketingCodeAdd: "marketing-code/avail-marketing-code",
  formSellerMyListing: "form/my-listing",
  formBuyerListing: "form/buyer-listings",
  formSellerGetSingleStartup: "form/startup-detail",
  deleteSellerListing: "form/delete-single",
  getSetting: "setting",
  getFavourite: "favourite",
  postFavourite: "favourite/add-start-up",
  postSetting: "setting/add",
  businessVerification: "form/business-verification",
  getSubscriptionTypes: "subscription/seller-subscriptions",
  addPromoCode: "promo-code/use-promo-code",
  getBuyerLogs: "listing-request/buyer-logs",
  sendBuyerRequest: "listing-request",
  postReportStartUp: "reported-start-ups",
  updateStartupRequest: "listing-request/for-buyer",
  getSellerLogs: "listing-request/seller-logs",
  getBuyerRequestLogs: "listing-request/for-seller",
  patchBuyerRequest: "listing-request/for-seller",
  promotePlans: "promote/seller-plans",
  checkPromotedBusiness: "promote/check-promote-business",
  addPromotedBusiness: "promote/promote-business",
  promotedBusinessList: "form/promoted-business-list",
  updateStartupToSold: "form/sold-unsold",
  notifications: "form/notifications",
  updateNotificationRead: "form/notificationRead",
  businessType: "form/business-types",
  allBusinessSetting: "subscription/all-business-setting",
  editStartupForm: "form/edit-startup-form",
  usersChangePassword: "users/change-password",
  usersChangeEmailRequest: "users/change-email-request",
  usersChangeEmailNew: "users/new-email-request",
  usersLogout: "users/logout",
  userSubscription: "subscription/user-subscription",
  checkSubscription: "users/check-subscription",
  dynamicLinkUrl: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks",
  cancelSubscription: "subscription/cancel-subscription",
  userChangeLanguage: "users/change-language",
  usersAppleLogin: "users/apple-login",
  checkStartupByStartupId: "listing-request/check-start-up",
  verifyPhoneNumberByOtp: "users/verify-phone-code",
  marketingCodeWithoutId: "marketing-code/check-marketing-code",
  userChangePhoneNumber: "users/change-phone-request",
  userVerifyPhoneNumber: "users/verify-phone-code",
  userUpdatePhoneNumber: "users/update-phone",
  usersResendPhoneCode: "users/resend-phone-code",
  formGetCities: "form/cities",
  usersCheckPhoneNumber: "users/check-phone",
};
