/** @format */

const arabicLabels = {
  signUpWithSeller: "قم بالتسجيل مع البائع",
  pleaseEnterDetailsToContinue: "الرجاء إدخال التفاصيل للمتابعة",
  emailAddress: "عنوان البريد الإلكتروني",
  password: "كلمة المرور",
  signUp: "إنشاء حساب جديد",
  signUpButtonText: "إنشاء الحساب",
  or: "أو",
  continueWithGoogle: "التسجيل عن طريق جوجل",
  saleAndInvestIn: "قم بالا",
  innovativeStartups: ["ستثمار في", <br />, "المشاريع"], // // Signup Page Label
  onOurPlatform: "الحذابة لدينا",
  signupDescription:
    "تصفح الالاف من المشاريع الموثقة و اكتشف المناسب لك، قيم صفقاتك المحتملة البناء والمقاولات على المعايير المالية، و كن مستعدا لتقديم عرضك",
  verifyEmail: ["قم بتأكيد بريدك الألكتروني", <br />, "لتأكيد حسابك"],
  verifyEmailDescription: `لقد أرسلنا رابط التحقق إلى عنوان البريد الإلكتروني الذي استخدمته للتسجيل.
  الرجاء الضغط على هذا الرابط لتأكيد حسابك. إذا لم تتمكن من رؤية البريد الإلكتروني، يرجى التحقق من مجلد الرسائل غير المرغوب فيها أو البريد غير المرغوب فيه.`,
  logOut: "تسجيل خروج",
  firstName: "الاسم الأول",
  lastName: "اسم العائلة",
  aboutYourSelf: "نبذة أخبرنا عن نفسك",
  skipNow: "تخطي",
  save: "حفظ",
  role: "المنصب/الصفة العملية",
  country: "الدولة",
  city: "المدينة",
  completeProfileDetails: "إكمال الملف التعريفي",
  pleaseCompleteDetailsToAccessAllFeatures:
    "يرجى استكمال التفاصيل للوصول إلى جميع الميزات",
  upload: "تحميل",
  profilePicture: "صورة الحساب",
  login: "تسجيل الدخول",
  forgetPassword: "هل نسيت كلمة المرور",
  doNotHaveAnAccount: "ليس لديك حساب؟ ",
  alreadyHaveAccount: "لديّك حساب؟",
  continue: "الخطوة التالية",
  whatYouWant: "كيف أساعدك؟",
  pleaseChooseAreYouASellerOrABuyer: [
    "لخدمتك بشكل أفضل الرجاء التحديد هل",
    <br />,
    "أنت بائع أم مشتري",
  ],
  ImABuyer: "مشتري",
  ImASeller: "بائع",
  enterYourEmailAddressWellSendYouOTPForNewPassword:
    "أدخل عنوان بريدك الإلكتروني وسنرسل لك كلمة المرور الجديدة لمرة واحدة (OTP).",
  send: "ارسال",
  verifyOTP: "التحقق من كلمة المرور",
  theCodeWasSentTo: "تم ارسال رمز التحقق على البريد الالكتروني",
  verify: "تأكيد",
  DidNotReceiveEmailYet: "لم تتلق البريد الإلكتروني بعد؟",
  resend: "إعادة إرسال",
  setNewPassword: "تعيين كلمة مرور جديدة",
  yourPasswordMustBeDifferentToPreviouslyUsedPassword:
    "يجب أن تكون كلمة المرور الخاصة بك مختلفة عن كلمة المرور المستخدمة مسبقًا.",
  allDone: "تم الاكتمال",
  yourPasswordHasBeenReset: " تم إعادة تعيين كلمة المرور الخاصة بك.",
  newPassword: "كلمة المرور الجديدة",
  confirmNewPassword: "تأكيد كلمة المرور الجديدة",
  back: "رجوع",
  changeLanguage: "تغيير اللغة",
  selectLanguage: "اختر اللغة للمتابعة",
  English: "إنجليزي",
  Arabic: "عربي",
  marketPlace: "ساحة عرض المشاريع",
  myDeals: "صفقاتي",
  upgrade: "يرقي",
  promoted: "مروجة",
  priceKey: "10 الف ريال سعودي",
  recent: "الحديثة",
  favorite: "المفضلة",
  recentStartup: "ساحة عرض المشاريع",
  totalStartups: "205 معروض",
  sortBy: "ترتيب حسب",
  askingPriceReasoning: "مبررات الالسعر البيع",
  askingDescription: [
    "استنادًا إلى تقرير مضاعفات الاستحواذ ربع السنوي الحالي",
    <br />,
    "نحن نطلب مضاعف إيرادات TTM بمقدار 5x",
  ],
  foundedDate: "03 مايو 2021",
  foundedDescription: "تاريخ التأسيس",
  noOfTeam: "عشرون",
  teamSize: "عدد الموظفين",
  businessTitleHere: "",
  businessId: "(#id121112345)",
  businessDescription:
    "عنوان العمل سيكون هناولكن لكي ترى من أين يأتي كل هذا الخطأ الفطري لأولئك الذين يتهمون اللذة ويمتدحون الألم، سأفتح الموضوع برمته، وأشرح نفس الأشياء التي قالها مكتشف الحقيقة هذا وكأنه مهندس حياة سعيدة. سأبدأ وسأشرح نفس الأشياء التي قالها مكتشف الحقيقة وكأنه مهندس الحياة السعيدة. ولكن لكي ترى من أين كل هذا الخطأ الذي ولده أولئك الذين يتهمون المتعة ومدحًا الألم، سأفتح الموضوع برمته، وأشرح نفس الأشياء التي قالها مكتشف الحقيقة وكأنه مهندس الحياة السعيدة",
  competitors: "المنافسون",
  loremIpsum: "لوريم إيبسوم دولور سميت",
  companyGrowth: "نمو المشروع",
  companyGrowthDescription:
    "ولكن لكي ترى من أين جاء كل هذا الميلاد هو خطأ أولئك الذين يتهمون اللذة ويمتدحون الألم، سأفتح الأمر برمته، وأشرح نفس الأشياء التي قالها مكتشف الحقيقة وكأنه مهندس حياة سعيدة.",
  keyAssets: "الأصول المهمة",
  finance: "المؤشرات المالية",
  upgradeRequest:
    "ترقية الملف الشخصي للحصول على حق الوصول لطلب مشروع الفضي والذهبي",
  learnMoreAndJoin: "تعرف على المزيد وانضم",
  promotedBusiness: "الأعمال المروجة",
  viewAll: "عرض الكل",
  marketPlace: "ساحة عرض المشاريع",
  myDeals: "صفقاتي",
  upgrade: "الإشتراكات",
  switchToSelling: "التحول إلى البيع",
  search: "بحث",
  yourProfileIsNotCompleted: "ملف التعريف الخاص بك لم يكتمل",
  yourProfileDesc:
    "تحتاج إلى إكمال ملف التعريف الخاص بك قبل أن تتمكن من الوصول إلى المشاريع المضافه",
  cancel: "إلغاء",
  letsComplete: "لنكمل",
  dontHaveAccessProfile: [
    "ليس لديك إمكانية الوصول إليها",
    <br />,
    "الأعمال الفضية قم بترقية ملفك الشخصي",
  ],
  youNeedToUpgradeProfile:
    "تحتاج إلى ترقية ملف التعريف الخاص بك حتى تتمكن من الوصول إلى الأعمال الفضية",

  wantToSeeBusiness: [
    "تبحث عن فرص استثمارية جديدة؟",
    <br />,
    "حول حسابك إلى مشتري الآن على إستحواذ!",
  ],
  wantToSeeBusinessSeller: [
    "جاهز للبيع؟ حول حسابك إلى بائع الآن",
    <br />,
    "على إستحواذ! اجذب رواد الاعمال والمستثمرين بخطوات بسيطة وسريعة.",
  ],
  ifYouWantToCompleted: "لاكمال عرض مشروعك، قم بالانتقال لحسابك كبائع",
  goBackToBuyerMode: "العودة الى حسابك كمشتري.",
  filter: "تصفية",
  clear: "مسح",
  apply: "تطبيق",
  hireExperts: "الإستعانة بخبير",
  whatHelpYouNeed: "نوع الإستشارة المطلوبة",
  ourExportWillTouch: "سيكون خبيرنا على اتصال خلال 72 ساعة",
  explainHere: "قم بالشرح هنا",
  startUpInList: "عدد المشاريع في قائمتك",
  upgradeAccount: "ترقية الحساب",
  CancelRequest: "إلغاء الطلب",
  learnMoreAndJoin: "تعرف على المزيد وانضم",
  hireExpert: "الإستعانة بخبير",
  favourites: "المفضلة",
  accountAndSetting: "الحساب & الاعدادات",
  logout: "تسجيل خروج",
  joinPremium: "انضم إلى بريميوم",
  platinum: "البلاتيني",
  premium: "بريميوم",
  package: "10 آلاف ريال سعودي",
  basicBusiness: "يمكن توسيع الأعمال الأساسية",
  silverBusiness: "يمكن توسيع الأعمال الفضية",
  requestAtTime: "5 طلبات تقديم عروض في وقت واحد",
  expandBasic: "قم بتوسيع الأعمال الأساسية",
  expandSilver: "قم بتوسيع الأعمال الفضية",
  unlimitedBusiness: "طلب غير محدود",
  buyThis: "اشتراك",
  settings: "إعدادات",
  manageSettings: "إدارة حسابك وتحديث التفاصيل الخاصة بك أدناه",
  myProfile: "الملف الشخصي",
  accountSetting: "إعدادات الحساب",
  johnDoe: "فلان الفلاني",
  founder: "مؤسس/رجل أعمال",
  editProfile: "تعديل الملف الشخصي",
  information: "المعلومات الشخصية",
  aboutUs: "نبذة",
  profileAbout:
    "ولكن لكي ترى من أين جاء كل هذا الميلاد هو خطأ أولئك الذين يتهمون اللذة ويمتدحون الألم، سأفتح الأمر برمته، وأشرح نفس الأشياء التي قالها مكتشف الحقيقة وكأنه مهندس حياة سعيدة.",
  change: "تغيير",
  changeEmail: "تغيير الايميل",
  changeCurrentEmail:
    "الرجاء إدخال عنوان بريدك الإلكتروني الحالي وسنرسل لك كلمة المرور لمرة واحدة (OTP) للتحقق من هويتك!",
  emailAddress: "عنوان البريد الإلكتروني",
  verifyOTP: "التحقق من كلمة المرور لمرة واحدة (OTP).",
  enterEmailOTP:
    "الرجاء إدخال عنوان بريدك الإلكتروني الحالي. وسنرسل لك كلمة المرور لمرة واحدة (OTP) للتحقق من هويتك!",
  setNewEmail: "تعيين بريد إلكتروني جديد",
  setEmailAddress: "أدخل عنوان بريدك الإلكتروني الجديد",
  emailChanged: "تم تغيير البريد الإلكتروني!",
  yourEmailHasBeenChangedSuccessfully: "لقد تم تغيير بريدك الإلكتروني بنجاح",
  changePassword: "تغيير كلمة المرور",
  oldPassword: "كلمة المرور القديمة",
  newPassword: "كلمة المرور الجديدة",
  confirmPassword: "تأكيد كلمة المرور",
  passwordChanged: "تم تغيير كلمة السر",
  yourPasswordHasBeenChangedSuccessfully:
    "لقد تم تغيير كلمة المرور الخاصة بك بنجاح",
  selectLanguage: "الرجاء تحديد لغتك الحالية",
  myListing: "قائمة مشاريعي",
  addedStartup: "الشركات الناشئة المضافة",
  addStartupForSell: "إضافة مشروع جديد",
  needInspiration: "هل تحتاج إلى بعض الإلهام؟",
  notification: "إشعارات",
  rejectedError: "تم رفض قائمتك  عنوان مشروع (#id121121) من قبل المشرف",
  reasonByAdmin: "السبب من قبل المشرف",
  rejectionTitle: "Sed ut perspiciatis unde omnis iste natus error sit",
  rejectionDescription:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore .",
  contactUs: "اتصل بنا",
  promoteAndFaster: "الترويج وزيادة فرص بيع المشروع",
  pleaseSelectThePlan:
    "يرجى اختيار خطة الترويج المناسبة لمتطلباتك من الخيارات الثلاثة التالية",
  selectedStartup: "المشروع",
  selectPromotePlan: "حدد خطة الترويج",
  promoteThirtyDays: "روَج مشروعي لمدة 30 يوم",
  promoteFourteenDays: "روَج مشروعي لمدة 14 يوم",
  promoteSevenDays: "روَج مشروعي لمدة 7 أيام",
  priceDetailsCode: "تفاصيل الأسعار والرمز الترويجي",
  promoCode: "رمز ترويجي",
  promoHere: "الرمز الترويجي هنا",
  totalAmount: "الاجمالي",
  promoPay: "ادفع 1224 ريال سعودي",
  paymentMethod: "طريقة الدفع او السداد",
  addNewCard: "إضافة بطاقة جديدة",
  cardLabel: "لا بطاقة",
  cardNum: "0000-0000-0000",
  expiryDate: "تاريخ الانتهاء",
  securityCode: "رمز الحماية",
  billingAddress: "عنوان وصول الفواتير",
  countryRegion: "البلد/المنطقة",
  congratulation: "تهانينا!",
  yourStartupPromotedForDays:
    "يتم الترويج لشركتك الناشئة (#id1234456) لمدة 30 يومًا",
  okay: "تمام",
  viewListing: "عرض المشروع",
  buyerRequest: "طلبات المشترين",
  accept: "قبول",
  reject: "رفض",
  sendDetailsToBuyer: "إرسال التفاصيل إلى المشتري",
  sendPhoneDetailsToBuyer: "الرجاء إختيار طريقة معلومات التواصل",
  rejectRequest: "رفض الطلب",
  rejectTagline: "هل أنت متأكد من رفضك لهذا الطلب؟",
  yesReject: "نعم أرفض",
  sendDefaultPhone: "إرسال رقم الهاتف الافتراضي",
  sendOtherPhone: "إرسال رقم هاتف آخر",
  sendPhoneNumber: "د ډیفالټ تلیفون شمیره واستوئ",
  detailsSendToBuyer: "ارسال التفاصيل للمشتري",
  yourDetailSend: "تم ارسال تفاصيلك",
  whyChoosePlatinum: "ولې پلاټینیم غوره کړئ",
  paymentDetails: "بيانات الدفع",
  promoCodeHere: "كود الخصم",
  priceDetails: "تفاصيل السعر",
  payTotal: "1224 RAR تادیه کړئ",
  totalPricePromoCode: "السعر الإجمالي مع الرمز الترويجي وضريبة القيمة المضافة",
  addNewCard: "نوی کارت اضافه کړئ",
  yourProfileIsPlatinum: "تمت الآن ترقية باقتك الى",
  viewUpdatedListing: "عرض القائمة",
  addNewStartupForSale: "اضافة مشروع جديد",
  businessName: "الاسم  المشروع",
  businessInfo: "معلومات المشروع",
  completeBusinessInfo: "اكمال متطلبات المشروع",
  dateFounded: "تاريخ التأسيس",
  startUpTeamSize: "حجم فريق مشروع",
  businessType: "نوع نشاط المشروع",
  startupHeadline: "وثيقة مشروع",
  description: "وصف",
  askingPrice: "سعر البيع",
  askingPriceReason: "طلب استدلال الالالسعر",
  buyerCannotSeeBusinessName: "لا يمكن للمشتري رؤية الاسم  النشاط التجاري",
  saveDraft: "حفظ المسودة",
  next: "التالي",
  goBackToSellerMode: "العودة إلى وضع البائع",
  switchToBuying: "التبديل إلى الشراء",
  myAquisition: "الاستحواذ الخاص بي",
  switchToSeller: "الانتقال الى حسابي كبائع",
  switchToBuyer: "التبديل إلى المشتري",
  all: "جميع المشاريع",
  approved: "موافق عليها",
  underReview: "تحت المراجعة",
  rejected: "مرفوض",
  draft: "مسودة",
  sold: "مباعة",
  copyRightsReservedIstehwath: `حقوق الطبع والنشر © ${new Date().getFullYear()} استحواذ`,
  accepted: "مقبول",
  favoriteBusiness: "المشاريع المفضلة",
  requestedBusiness: "طلباتي",
  accessRequestUnavailable: "طلب الوصول غير متاح",
  awaitingRequestUnavailable: "في انتظار قبول طلب الوصول",
  location: "الموقع على الخريطة؟",
  noOfCustomer: "عدد العملاء",
  ttmRevenue: "إيرادات ال12 شهر الماضية (TTM)",
  startupTeam: "عدد الموظفين",
  youDontHaveAddedAnyStartup: "ليس لديك اي مشاريع مضافة",
  needSomeInspiration: "هل تحتاج إلى بعض الإلهام؟",
  yourAccountWillBeSwitchToBuyer: "لقد تم تحويل حسابك إلى المشتري",
  verifyBusiness: "توثيق المشروع",
  ceo: "المدير التنفيذي",
  promote: "ترويج",
  wantToCompleteSelling: "لاكمال عرض مشروعك، قم بالانتقال لحسابك كبائع",
  wantToCompleteBuying:
    "إذا كنت تريد رؤية قائمتك، قم بالتبديل مرة أخرى إلى الشراء.",
  pleaseCompleteDetailstoAccess:
    "يرجى استكمال التفاصيل للوصول إلى جميع الميزات",
  remove: "إزالة",
  selectDate: "حدد تاريخ الميلاد",
  updatedSuccess: "نجاح محدث",
  success: "!نجاح",
  socialLoginSuccessfull: "نجاح تسجيل الدخول الاجتماعي",
  editStartupForSale: "تحرير بيانات المشروع",
  businessLocation: "موقع المشروع",
  addressOne: "العنوان 1",
  youDontHaveAnyStartup: "ليس لديك أي مشروع كما",
  fileUploadFailed: "فشل تحميل الملف",
  numberOfCustomer: "عدد الزبائن",
  or: "أو",
  documentAreConfidentailsAndWontBeSharedWithBuyers:
    "المستندات سرية ولن تتم مشاركتها مع المشترين",
  adWillBeHighlightedToTopPositions:
    "سيتم تسليط الضوء على الإعلان في المواضع العليا",
  promoDiscount: "الخصم الترويجي",
  pay: "دفع",
  oops: "أُووبس",
  paymentFailed: "فشل دفع معاملتك.",
  searchResult: "نتيجة البحث",
  price: "السعر",
  revenue: "الإيرادات",
  dateOfListing: "تاريخ الإدراج",
  newToOld: "الجديد إلى القديم",
  oldToNew: "القديم إلى الجديد",
  highToLow: "الاعلى الى الادنى",
  lowToHigh: "الادنى الى الاعلى",
  businessCategory: "فئة المشروع",
  searchLocation: "البحث عن الموقع",
  noNotifications: "لا يوجد إشعارات",
  yesterday: "أمس",
  today: "اليوم",
  initiated: "بدأت",
  startupHasBeen: "لقد تم مشروع",
  yourRequestTo: "طلبك ل",
  startupTitle: "عنوان المشروع",
  noDataAvailableForBuyerRequest: "ليس لديك أي طلبات في الوقت الحالي!",
  requested: "مطلوب",
  notifications: "إشعارات",
  statusUpdatedToSold: "تم تحديث الحالة للبيع!",
  paymentFailed: "فشل دفع معاملتك.",
  oops: "أُووبس",
  yourStartupHasBeenPromoted: "مشروعك قيد الترويج لمدة",
  yourStartupHasBeenPromotedForThirty: "روّج مشروعي لمدة 30 أيام",
  yourStartupHasBeenPromotedForFourteen: "روّج مشروعي لمدة 14 أيام",
  yourStartupHasBeenPromotedForSeven: "روّج مشروعي لمدة 7 أيام",
  daysRemaining: "الأيام المتبقية",
  noDataFoundInBuyerRequest: "لم يتم العثور على بيانات في طلبات المشترين.",
  view: "عرض",
  pending: "قيد الانتظار",
  promoDiscount: "الخصم الترويجي",
  adWillBeHighlightedToTopPositions:
    "سيتم تسليط الضوء على الإعلان في المواضع العليا",
  getNoticedWithFeaturedTagInAOption:
    "تميّز بوجود مشروعك في أعلى قائمة المشاريع",
  successfully: "بنجاح",
  phoneNoisRequired: "رقم الالهاتف مطلوب!",
  documentAreConfidentailsAndWontBeSharedWithBuyers:
    "المستندات سرية ولن تتم مشاركتها مع المشترين",
  sentRequest: "الطلب المرسل",
  or: "أو",
  youHaveNoFavorite: "ليس لديك مشاريع معروضة",
  sar: "ر.س",
  startupDetail: "تفاصيل المشروع",
  pleaseFillAllRequiredFields: "يرجى تعبئة جميع الحقول المطلوبة.",
  startupReportedSuccess: "ذكرت مشروع النجاح!",
  reportStartupReason: "الإبلاغ عن المشروع",
  report: "إرسال",
  reportReason: "تفاصيل البلاغ",
  addReportReason: "أضف سبب التقرير",
  requestSendSuccess: "طلب إرسال النجاح!",
  sendRequest: "ارسل طلب للتواصل",
  deals: "صفقات",
  youDontHaveAnyDeals: "حاليا ليس لديك أي صفقات",
  noDealsAvailable: "لا توجد عروض متاحة",
  favoriteRemoved: "تمت إزالة المفضلة",
  unLimitedRequest: "طلبات تقديم عروض غير محدودة",
  RequestAtATime: "طلبات في وقت واحد",
  business: "عمل",
  canExpand: "يمكن أن تتوسع",
  fileUploadFailed: "فشل تحميل الملف",
  youDontHaveAnyStartup: "ليس لديك أي مشروع كما",
  sell: "يبيع",
  enterTheOtpSendToYou:
    "الرجاء إدخال OTP الذي تم إرساله إلى بريدك الإلكتروني الجديد للتحقق من هويتك!",
  skip: "تخطي",
  thisIsYourListing: "قائمتي",
  recent: "الحديثة",
  shown: "عدد الاعمال المعروضة",
  favoriteStartup: "المشاريع المفضلة",
  favoriteAdded: "المفضلة المضافة",
  selectCountry: "حدد الدولة",
  yourRoleInBusiness: "صفتك الرسمية في المشروع",
  select: "اختيار",
  addressOne: "العنوان 1",
  // businessLocation: "موقع العمل",
  ttmNetProfit: "TTM صافي الربح",
  ttmGrossRevenue: "إيرادات الـ 12 شهر الماضية (TTM)",
  ttmNetRevenue: "صافي الربح خلال الـ 12 شهر الماضية (TTM)",
  ttmGrossProfit: "إجمالي الربح (TTM)",
  uploadLetterWithCompanyHeader: "تحميل خطاب بشعار الشركة الرسمي",
  netProfit: "صافي الربح",
  grossRevenue: "إجمالي الإيرادات",
  addBusinessFinanceDetails: "أضف تفاصيل تمويل الأعمال ",
  financial: "المؤشرات المالية",
  urlCopiedToClipboard: "تم نسخ عنوان URL إلى الحافظة!",
  listing: "قائمة",
  delete: "حذف المشروع",
  areYouSureYouWantToDelete: "هل أنت متأكد أنك تريد حذف مشروعك",
  deletedSellerListingSuccess: "تم حذف قائمة البائع بنجاح!",
  fillMarketingCode: "ملء رمز التسويق!",
  submit: "إنهاء",
  marketingCode: "الرمز الترويجي",
  addMarketingCodeIfAvailable: "قم بإضافة الرمز الترويجي إن وجد!",
  submitYourListingItwillTakeDaytoReview:
    "قم برفع طلب ادراج لمشروعك وسنقوم بمراجعة الطلب خلال مدة لا تزيد عن 24 ساعة، سيتم تنبيهك فور اعتماد الادراج؟",
  verifyAndListingisReady: "تم التحقق والمشروع جاهز للإدراج",
  pleaseCheckYourEmailBox:
    "البريد الإلكتروني المسجل لدينا يرجى التحقق وادخال الرمز للتأكيد",
  weHaveSentYouASixDigitOtp: "لإتمام عملية ادراج المشروع لقد أرسلنا لك رمز على",
  enterOtpToVerify: "ادخل الرمز المرسل لمرة واحدة لتحقق",
  verificationIdentify: "ستتم مراجعة هوية التحقق والمحتوى.",
  typeHere: "أضف قائمة المنافسين",
  no: "لا",
  yes: "نعم",
  socialLoginSuccessfull: "Social Login Success!",
  toUpload: "لتحميله",
  chooseFile: "أو اختر ملف",
  dragAndDropOr: "اسحب وأدرج",
  success: "!نجاح",
  detailsUpdatedSuccess: "تم تحديث التفاصيل بنجاح!",
  updatedSuccess: "نجاح محدث!",
  selectDate: "حدد تاريخ",
  pleaseCompleteDetailstoAccess:
    "يرجى استكمال التفاصيل للوصول إلى جميع الميزات",
  dateOfBirth: "تاريخ الميلاد",
  yourPwHasBeenReset: "تم إعادة تعيين كلمة المرور الخاصة بك",
  passwordDoesntMatch: "كلمة المرور غير متطابقة!",
  ResendSuccessOtpSend: "إعادة إرسال OTP النجاح!",
  otpMatchSuccess: "تمت مطابقة OTP بنجاح!",
  successOtpSend: "نجاح OTP إرسال!",
  editProfileDetails: "تعديل تفاصيل الملف الشخصي",
  didNotReceiveEmailYet: "لم تتلق البريد الإلكتروني بعد؟",
  TTMRevenue: "إيرادات ال١٢ شهر الماضية (TTM)",
  DateFounded: "تاريخ التأسيس",
  StartupTeam: "فريق البدء",
  signUpSuccessful: "الاشتراك بنجاح",
  signUpSuccess: "تم الاشتراك بنجاح",
  signInSuccess: "تم تسجيل الدخول بنجاح",
  pleaseFillTheField: "يرجى ملء الحقل",
  verifyBtn: "تأكيد",
  passwordValue: "******",
  supportTeam: "لديك أي أسئلة؟ اتصل بنا",
  watsAppSupport: "دعم الواتساب",
  watsAppNumber: "+966 55 024 6646",
  language: "اللغة",
  support: "الدعم",
  supportIstehwath: "support@istehwath.net",
  phoneNumber: "رقم الهاتف",
  saudiaPhoneCode: "+966",
  financials: "المؤشرات المالية",
  marketplace: "ساحة عرض المشاريع",
  changePwSuccess: "تغيير كلمة المرور بنجاح!",
  otpSendToEmail: "OTP إرسال إلى البريد الإلكتروني الحالي",
  pleaseFillTheOtp: "يرجى ملء Otp",
  changeEmailSuccess: "تم تغيير البريد الإلكتروني بنجاح!",
  share: "مشاركة",
  sentPhoneNumber: "رقم الالهاتف المرسل",
  yourListing: "طلب الادراج لمشروعك",
  rejectStartupTitle: "(أسم المشروع)",
  isRejectedByAdmin: "مرفوضة من قبل المشرف",
  whyChoose: "لماذا الاختيار",
  upgradeMemberShip: "ترقية العضوية",
  youDontHaveAnAccessTo: "ليس لديك إمكانية الوصول للمشاريع",
  businessUpgradeYourMembership: "وتحتاج الى ترقية",
  businessUpgradeYourMembershipForGold:
    "وتحتاج الى ترقية عضويتك قبل اتمام الطلب",
  youNeedToUpgradeYourMembershipThenYouCanAccess:
    "تحتاج إلى ترقية عضويتك ثم يمكنك إرسال الطلب",
  businessSmall: "عمل. ",
  enterTheOtpSendToYourNewEmail: "أدخل Otp أرسل إلى بريدك الإلكتروني الجديد",
  thereAreNoStartupForSale: "لا توجد شركات ناشئة للبيع في الوقت الراهن!",
  areYouSureYouWantToCancel: "هل أنت متأكد أنك تريد إلغاء",
  request: "طلب",
  yesCancel: "نعم، قم بالإلغاء",
  copy: "نسخ",
  copied: "تم النسخ",
  istehwath: "إستحواث",
  wrongFileType: "تنسيق نوع الملف خاطئ",
  notAvailable: "غير متاح",
  reason: "سبب",
  startup: "مشروع",
  requests: "طلبات",
  startUpsInList: "عدد المشاريع في قائمتك",
  cancelSubscription: "إلغاء الاشتراك",
  subscription: "الاشتراك",
  basic: "شهري",
  silver: "الفضية",
  gold: "الذهبية",
  areYouSureYouWantToCancelSubscription:
    "هل أنت متأكد أنك تريد إلغاء الاشتراك؟",
  subscribeToEmailing:
    "اشترك لتلقي آخر التحديثات والعروض الحصرية ورسائل البريد الإلكتروني التسويقية",
  noSearchFoundIn: "لم يتم العثور على البحث",
  youAlreadyHaveAPlatinum:
    "لديك بالفعل اشتراك بلاتيني. لا يمكنك شراء الاشتراك المميز.",
  youAlreadyHaveThisSubscription: "لديك بالفعل هذا الاشتراك!",
  saveCardInformation:
    "سيتم حفظ معلومات بطاقتك بشكل آمن عند تقديمها للاشتراك. يمكنك إدارة وإلغاء اشتراكك في أي وقت من صفحة الإعدادات.",
  buyerCannotSeeLocation: "لا يمكن للمشتري رؤية الموقع الدقيق للمشروع",
  noExpertsAvailableAtTheMoment: "لا يوجد خبراء متاحين في الوقت الراهن",
  deleteRequest: "حذف مشروع",
  email: "بريد إلكتروني",
  cancelled: "ألغيت",
  denied: "رفض",
  rejected: "مرفوض",
  approved: "موافق عليها",
  initiated: "بدأت",
  accepted: "مقبول",
  acceptedBusinesses: "المشاريع المقبولة",
  questionMark: "؟",
  yourAccountHasBeenSwitched: "لقد تم تبديل حسابك",
  pleaseFillThisField: "من فضلك املأ هذا الحقل",
  invalidEmailAddress: "عنوان البريد الإلكتروني غير صالح",
  cantBeOnlySpaces: "لا يمكن أن تكون مجرد مسافات",
  minimumThreeCharactersRequired: "مطلوب ثلاثة أحرف على الأقل",
  noInternetConnectionFound: "!لا يوجد اتصال بالإنترنت في الوقت الراهن",
  invalidPhoneNo: "رقم الالهاتف غير صالح",
  by: "بواسطة",
  youDonotDefaultNumber: "ليس لديك الرقم الافتراضي. يرجى تعديل رقمك أولا",
  nobusinessForSaleHaveBeenAdded: "لم تقم بإضافة أي مشروع للبيع",
  otherRole: "دور آخر",
  seller: "تاجر",
  buyer: "مشتري",
  rememberMe: "تذكرنى",
  requiredField: "يرجى تعبئة الحقول المطلوبة",
  allListing: "الكل",
  noUnderReviewFoundText: "ليس لديك مشاريع تحت المراجعة",
  noApprovedFoundText: "ليس لديك مشاريع موافق عليها",
  noDeniedFoundText: "ليس لديك مشاريع مرفوضة",
  allListingFoundText: "ليس لديك اي مشاريع مضافة",
  noDraftFoundText: "ليس لديك مشاريع كمسودة",
  noSoldFoundText: "ليس لديك مشاريع مباعة",
  cancelRequest: "إلغاء الطلب",
  youDontHaveAnyDealsAsFavorite: "ليس لديك مشاريع مفضلة!",
  youDontHaveAnyDealsAsAccepted: "ليس لديك مشاريع مقبولة!",
  youDontHaveAnyDealsAsRequested: "ليس لديك مشاريع في قائمة الطلبات!",
  free: "مجاني",
  joinSubscription: "باقات استحواذ",
  monthly: "شهري",
  yearly: "سنوي",
  hireExpertSuccessMessage:
    "نعتذر منك، خدمة الاستشارات لم تُفعل بعد ولكن طلبك وصلنا وفي حال توفر الخدمة ستكون لك الأولية في التواصل!",
  addedBusiness: "المشاريع المضافة",
  approvedBusiness: "مشاريع موافق عليها",
  underReviewBusiness: "مشاريع تحت المراجعة",
  draftBusiness: "مشاريع مسودة",
  rejectedBusiness: "مشاريع مرفوضة",
  soldBusiness: "مشاريع مباعة",
  continueWithApple: "التسجيل عن طريق أبل",
  en: "en",
  ar: "ar",
  pleseEnterTheOneTimeVerificationCode:
    "يرجى ادخل رمز التحقق لمرة واحدة للمتابعة",
  businesses: "المشاريع",
  youAlreadyHaveAPlatinumSubscriptionYouCantDownGrade:
    "لديك إشتراك بلاتيني فعّال",
  shownBusinesses: "عدد المشاريع المعروضة",
  pleaseAcceptToOurTermsAndConditions: [
    <label htmlFor='termsCondition' className='cursor-pointer'>
      {" "}
      قبول{" "}
    </label>,
    <a
      href={"https://istehwath.net/terms-conditions/"}
      target={"_blank"}
      className={"underline cursor-pointer"}
    >
      الشروط والأحكام
    </a>,
    <span> و </span>,
    <a
      href={"https://istehwath.net/privacy-policy/"}
      target={"_blank"}
      className={"underline cursor-pointer"}
    >
      سياسة الخصوصية
    </a>,
  ],
  pleaseCheckOurTermsAndConditionToContinue:
    "يرجى الاطلاع على الشروط والأحكام وسياسة الخصوصية للمتابعة",
  authorizationLetter: "خطاب تفويض من الغرفه التجارية",
  toGiveYourBusinessAMarkOfAuthentication:
    "لإضفاء علامة التوثيق على مشروعك يجب عليك ارفاق خطاب تفويض من الغرفة التجارية بترويسة تحتوي على شعار الشركة والمحتوى التالي:'أفوض منصة استحواذ بعرض مشروع (أسم المشروع) على المهتمين وفق ماقدمته من بيانات دون أدنى مسؤولية على المنصة'",
  authenticatingYourBusinessWillIncreaseTheChance:
    "هذه الخطوة اختيارية ولكن توثيق المشروع يزيد من فرص بيع المشروع",
  iDeclareThatIHaveFullAuthorityToList:
    "أقر بأنني أملك كامل صلاحية التصرف في المشروع وأنني أرغب في عرضه على منصة استحواذ",
  markAsUnSold: "تعيين كغير مباع",
  areYouSureYouWantToUnsold: "هل أنت متأكد أنك تريد إلغاء بيع مشروعك",
  unsoldRequest: "طلب تغيير حالة المشروع",
  weHaveSentYouASixDigitOtpOnNumber:
    "لقد أرسلنا لك رمزًا مكونًا من 4 أرقام على الهاتف",
  changePhoneNumber: "غير رقم الهاتف",
  phoneNumberChanged: "تم تغيير رقم الهاتف",
  yourPhoneHasBeenChangedSuccessfully: "لقد تم تغيير رقم هاتفك بنجاح",
  enterTheOtpSendToYouOnYourPhone:
    "الرجاء إدخال كلمة المرور لمرة واحدة (OTP) المرسلة إلى رقم هاتفك الجديد للتحقق من هويتك!",
  invalidPhoneNumber: "رقم الهاتف غير صحيح",
  pleaseCompleteRequiredFields: "يرجى تعبئة الحقول المطلوبة",
  businessIsCertifiedByTheChamberOfCommerce:
    "هذا المشروع مُصدق من الغرفة التجارية",
  done: "إدراج المشروع",
  pleaseAcceptOur: " قبول ",
  termsAndCondition: "الشروط والأحكام",
  and: " و ",
  privacyPolicy: "سياسة الخصوصية",
  enterTheOtpSendToYourPreviousEmail:
    "أدخل Otp أرسل إلى بريدك الإلكتروني الحالي",
  addBusinessDetails: "أضف تفاصيل المشروع",
  codeMustBeEntered: "يجب إدخال الرمز",
  ascending: "تصاعدي",
  descending: "تنازلي",
  requestedNumberCantBeEqualToPrevious:
    "يجب أن يكون الرقم الجديد المطلوب مختلفا عن الرقم السابق!",
  businessNameIsHidden: "اسم المشروع مخفي",
  Seller: "البائع",
  ttm: "(TTM)",
  Revenue: "إيرادات ال12 شهر الماضية ",
  selectedPlan: "الخطة المختارة",
  promotedBUSINESS: "مشروع مُرَوَج",
  phoneNumberNotAvailable: "رقم الهاتف غير متوفر",
  admin: "المشرف",
  UPGRADE: "الاشتراك",
  expiresOn: "ينتهي في",
  autoRenewOn: "يجدد تلقائيا في",
  previousSubscriptionHasExpired: "انتهت صلاحية اشتراكك",
  enterTheOtpSendToYouOnYourOldPhone:
    "الرجاء إدخال كلمة المرور لمرة واحدة (OTP) المرسلة إلى رقم الهاتف السابق للتحقق من هويتك!",
  areYouSureYouWantToSell: "هل انت متأكد من وضع علامة (تم البيع) على مشروعك؟",
  soldRequest: "طلب تغيير حالة المشروع",
  yourProfileDescriptionIfProfileNotCompleted:
    "تحتاج إلى إكمال ملف التعريف الخاص بك قبل أن تتمكن من ترقية باقتك.",
  yourBusinessHasBeenPromotedFor: "روّج مشروعي لمدة",
  days: "أيام",
};

export default arabicLabels;
